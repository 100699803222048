export function StatusRenderer({ value }) {
  if (!value) {
    return null;
  }
    console.log('StatusRenderer', value);

    const statusMap = {
      PENDING: "badge bg-secondary w-100",
      LIVE: "badge bg-success w-100",
      INACTIVE: "badge bg-danger w-100",
      PAUSED: "badge bg-warning w-100",
    };
  
    const status = value.toUpperCase();
    const className = statusMap[status];
    
   

    return className ? <span className={className}>{status.toUpperCase()}</span> : 
    <span className="badge bg-secondary w-100">PENDING</span>;
}

export function StatusCellTooltipRenderer({ value }) {
  if (!value) {
    return null;
  }
  
  console.log('StatusCellTooltipRenderer', value);
  const status = value.toUpperCase();

  if (status === "PENDING") {
    return "Your bid is pending, your campaign will begin delivering shortly";
  }

  if (status === "PAUSED") {
    return "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.";
  }

  return "Your status is " + value;
}

  