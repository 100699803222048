import { formatThousand } from "../../../../helpers/thousand-number-format";

export function ClusterYourBidCellRenderer({ value }) {
    const your_bid = value.split("@")[0];
    const current_cpc = value.split("@")[1];
    const min_cpc = value.split("@")[2];
    const status = value.split("@")[3].toUpperCase();

    if (value === "-") {
        return "No bid";
    }

    if (status === "INACTIVE" || status === "DRAFT") {
        return (
            <div>
                <span className="text-secondary">{your_bid}</span>
            </div>
        )
    }

    if (Number(current_cpc) > Number(min_cpc)) {
        return (
            <div>
                <span className="text-success">{your_bid}</span>
            </div>
        )
    } else {
        return (
            <div>
                <span>${
                    formatThousand(Number(min_cpc).toFixed(2))
                }</span>
            </div>
        )
    }
}
 

export function ClusterYourBidCellTooltipRenderer({ value }) {
    if (value === "-") {
        return "Your bid is not set or campaign is paused or not active";
    }

    return "Your bid is " + value;
}
 