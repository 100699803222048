export function ClusterPositionRenderer({ value }) {
    if (!value) {
        return null;
    }

    const bids_amount = Number(value.split("@")[2]);
    let currentPos = Number(value.split("@")[0]);
    let estimatedPos = Number(value.split("@")[1]);
    let ppc_bid_active = value.split("@")[3];
    let campaign_active = value.split("@")[4];
    let ppc_paused = value.split("@")[5];

    if (campaign_active === "false") {
        return (
            <div class="d-flex align-items-center text-secondary">
                Not shown
            </div>
        );
    }

    if (currentPos > bids_amount || currentPos === 0) {
        currentPos = "Not shown";
    }

    if (estimatedPos > bids_amount || estimatedPos === 0) {
        estimatedPos = "Not shown";
    }

    if (currentPos !== "Not shown") {
        currentPos = "#" + currentPos;
    }

    if (estimatedPos !== "Not shown") {
        estimatedPos = "#" + estimatedPos;
    }

    if (estimatedPos === "Not shown") {
        return (
            <div class="d-flex align-items-center text-secondary">
                Not shown
            </div>
        );
    }

    if (currentPos !== "Not shown" && estimatedPos == currentPos) {
        return (
            <div class="d-flex align-items-center ml-1">
                <span className="text-success fw-bold">{estimatedPos}</span>
            </div>
        );
    }

    return (
        <div class="d-flex align-items-center">
            <span className="text-secondary">{currentPos}</span>
            <i class="ri-arrow-right-line ml-2 mr-2" data-bs-toggle="tooltip" title="Estimated position"></i>
            <span className="text-success fw-bold">
                {estimatedPos}
            </span>
        </div>
    );
}

export function ClusterPositionCellTooltipRenderer({ value }) {
    if (!value) {
        return null;
    }
    
    const bids_amount = Number(value.split("@")[2]);
    let currentPos = Number(value.split("@")[0]);
    let estimatedPos = Number(value.split("@")[1]);
    let ppc_bid_active = value.split("@")[3];
    let campaign_active = value.split("@")[4];
    let ppc_paused = value.split("@")[5];

    if (bids_amount < currentPos) {
        return `You current position is not shown.`;
    }

    if (campaign_active === "false") {
        return `Your campaign is not active. That's why your position is not shown.`;
    }

    if (currentPos === 0) {
        return `Your current position is not shown.`;
    }

    if (currentPos <= bids_amount && currentPos > 0) {
        if (currentPos === 1) {
            return `Your current position is #${currentPos}.`;
        } else {
            if (currentPos === estimatedPos) {
                return `Your current position is #${currentPos}.`;
            }

            return `Your current position is #${currentPos}. But you can get to position #${estimatedPos} if you increase your bid.`;
        }
    }
}