import React, { Fragment, useEffect, useState } from "react"
import Header from "../layouts/Header"
import { Link } from "react-router-dom"
import { getUserRole } from "../helpers/user-role"
import { useSelector } from "react-redux"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { editPublisher, getPublisher } from "../services/pub"
import { toast } from "react-toastify"
import { getAllBillingTypes } from "../services/finance"
import getAllCountires from "../services/adv/get_all_countires"
import Select from "react-select";
import { getAllRoles } from "../services/micro"

export const GlobalConfigurations = () => {
    const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
    const user = useSelector((state) => state.user);
    const [skin, setSkin] = useState(currentSkin);
    const [publisher, setPublisher] = useState();
    const [countries, setCountries] = useState([]);
    const [billingTypes, setBillingTypes] = useState();
    const [systemBillingTypes, setSystemBillingTypes] = useState();
    const [unpaidCountries, setUnpaidCountries] = useState();
    const [userAccesses, setUserAccesses] = useState([]);
    const [roles, setRoles] = useState([]);
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    const handleCountriesChange = (selectedOption) => {
        if (!selectedOption) {
            return;
        }

        setUnpaidCountries(selectedOption);
    };

    const handleBillingTypeChange = (selectedOption) => {
        if (!selectedOption) {
            return;
        }

        setBillingTypes(selectedOption);
    }

    useEffect(() => {
        getPublisher(user.publisher_id)
            .then((pub_res) => {
                if (pub_res.success) {
                    setPublisher(pub_res.data)
                }
            })

        getAllCountires()
            .then((res) => {
                if (res.success) {
                    setCountries(res.data);
                    getPublisher(user.publisher_id)
                        .then((pub_res) => {
                            if (pub_res.success) {
                                if (pub_res.data.unpaid_countries) {
                                    setUnpaidCountries(res?.data?.filter((unpaid_country) => pub_res.data.unpaid_countries.includes(unpaid_country.id)).map((unpaid_country) => {
                                        return {
                                            value: unpaid_country.id,
                                            label: unpaid_country.name
                                        };
                                    }));
                                } else {
                                    setUnpaidCountries([]);
                                }
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        getAllBillingTypes()
            .then((res) => {
                if (res.success) {
                    getPublisher(user.publisher_id)
                        .then((pub_res) => {
                            if (pub_res.success) {
                                if (pub_res.data.approved_billing_types) {
                                    setBillingTypes(res?.data?.filter((billing_type) => pub_res.data.approved_billing_types.includes(billing_type.id)).map((billing_type) => {
                                        return {
                                            value: billing_type.id,
                                            label: billing_type.name
                                        };
                                    }));
                                } else {
                                    setBillingTypes([]);
                                }
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                    setSystemBillingTypes(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        getAllRoles()
            .then((res) => {
                if (res.success) {
                    setRoles(res.data);

                    const userAccessesData = res.data.find(
                        (role) => role.name === user.role
                    )?.avaliable_accesses;
                    setUserAccesses(userAccessesData);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        const handleScroll = () => {
            setShowScrollToTop(window.scrollY > 300); // Show button if scrolled 300px down
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const updatePublisher = () => {
        editPublisher(user.publisher_id, {
            default_advertiser_index_url: publisher.default_advertiser_index_url,
            top_up_threshold: publisher.top_up_threshold,
            default_budget: publisher.default_budget,
            default_cpc: publisher.default_cpc,
            min_budget: publisher.min_budget,
            min_cpc: publisher.min_cpc,
            country_id: publisher.country_id,
            payment_delay_in_days: publisher.payment_delay_in_days,
            default_currency: publisher.default_currency,
            min_cpc_hirarchy_level_1: publisher.min_cpc_hirarchy_level_1,
            min_cpc_hirarchy_level_2: publisher.min_cpc_hirarchy_level_2,
            min_cpc_hirarchy_level_3: publisher.min_cpc_hirarchy_level_3,
            approved_billing_types: billingTypes.filter((billing_type) => billing_type.value).map((billing_type) => billing_type.value),
            logo_width_dimension: publisher.logo_width_dimension,
            logo_height_dimension: publisher.logo_height_dimension,
            screenshot_width_dimension: publisher.screenshot_width_dimension,
            screenshot_height_dimension: publisher.screenshot_height_dimension,
            min_initial_card_top_up: publisher.min_initial_card_top_up,
            min_initial_invoice_top_up: publisher.min_initial_invoice_top_up,
            min_auto_card_threshold: publisher.min_auto_card_threshold,
            min_auto_card_top_up: publisher.min_auto_card_top_up,
            min_auto_invoice_top_up: publisher.min_auto_invoice_top_up,
            min_auto_invoice_threshold: publisher.min_auto_invoice_threshold,
            cluster_bidding_mode_enabled: publisher.cluster_bidding_mode_enabled,
            cluster_enforce_mode_enabled: publisher.cluster_enforce_mode_enabled,
            default_theme: publisher.default_theme,
            unpaid_countries: unpaidCountries.filter((country) => country.value).map((country) => country.value),
        }).then((res) => {
            if (res.success) {
                toast.success("Publisher updated successfully");
            } else {
                toast.error("Publisher update failed");
            }
        });
    };

    const switchSkin = (skin) => {
        if (skin === "dark") {
            const btnWhite = document.getElementsByClassName("btn-white");

            for (const btn of btnWhite) {
                btn.classList.add("btn-outline-primary");
                btn.classList.remove("btn-white");
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName(
                "btn-outline-primary"
            );

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove("btn-outline-primary");
                btn.classList.add("btn-white");
            }
        }
    };

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        userAccesses.includes("global_variables_access") && (
            <Fragment>
                <Header onSkin={setSkin} />
                <div className="main main-app p-3 p-lg-4">
                    <ol className="breadcrumb fs-sm mb-2">
                        <li className="breadcrumb-item">
                            <Link to="#">{getUserRole(user)}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Global Configurations
                        </li>
                    </ol>
                    <h2 className="main-title">Global Configurations</h2>


                    <Card id="globalVariables" className="card-settings mt-3">
                        <Card.Header>
                            <div className="d-flex justify-content-between">
                                <div className="">
                                    <Card.Title className="mb-0">Global Variables</Card.Title>
                                </div>
                                <div className="">
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={() => {
                                            updatePublisher();
                                        }}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Default Advertiser Index URL</h6>
                                        <p>
                                            Default URL for redirecting to the advertiser's website
                                        </p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    default_advertiser_index_url: e.target.value,
                                                });
                                            }}
                                            value={publisher?.default_advertiser_index_url}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Default Top Up Threshold, $</h6>
                                        <p>Default threshold for new clients</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    top_up_threshold: e.target.value,
                                                });
                                            }}
                                            value={publisher?.top_up_threshold}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Default Clients Budget, $</h6>
                                        <p>Default budget for new clients</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    default_budget: e.target.value,
                                                });
                                            }}
                                            value={publisher?.default_budget}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Default CPC, $</h6>
                                        <p>Default CPC for new clients</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    default_cpc: e.target.value,
                                                });
                                            }}
                                            value={publisher?.default_cpc}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min Budget, $</h6>
                                        <p>Minimum budget for new clients</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_budget: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_budget}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min CPC, $</h6>
                                        <p>Minimum CPC for new clients</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_cpc: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_cpc}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min CPC For Hirarchy Level #1</h6>
                                        <p>Minimum CPC for sub clusters</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_cpc_hirarchy_level_1: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_cpc_hirarchy_level_1}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min CPC For Hirarchy Level #2</h6>
                                        <p>Minimum CPC for sub clusters</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_cpc_hirarchy_level_2: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_cpc_hirarchy_level_2}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min CPC For Hirarchy Level #3</h6>
                                        <p>Minimum CPC for sub clusters</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_cpc_hirarchy_level_3: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_cpc_hirarchy_level_3}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Cluster Enforce Minimum CPC</h6>
                                        <p>Enable cluster enforce minimum CPC for different levels</p>
                                    </Col>
                                    <Col md>
                                        <Form.Check
                                            type="switch"
                                            id="clusterBiddingMode"
                                            label=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    cluster_bidding_mode_enabled: e.target.checked,
                                                });
                                            }}
                                            checked={publisher?.cluster_bidding_mode_enabled}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Cluster Enforce Parent Hirarchy</h6>
                                        <p>Enable cluster enforce parent hirarchy for sub-clusters</p>
                                    </Col>
                                    <Col md>
                                        <Form.Check
                                            type="switch"
                                            id="clusterEnforceMode"
                                            label=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    cluster_enforce_mode_enabled: e.target.checked,
                                                });
                                            }}
                                            checked={publisher?.cluster_enforce_mode_enabled}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Payment Delay</h6>
                                        <p>Maximum delay for payment in days</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    payment_delay_in_days: e.target.value,
                                                });
                                            }}
                                            value={publisher?.payment_delay_in_days}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min Initial Credit Card Top Up, $</h6>
                                        <p>Minimum initial credit card top up</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_initial_card_top_up: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_initial_card_top_up}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min Initial Invoice Top Up, $</h6>
                                        <p>Minimum initial invoice top up</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_initial_invoice_top_up: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_initial_invoice_top_up}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min Auto Credit Card Top Up, $</h6>
                                        <p>Minimum auto credit card top up</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_auto_card_top_up: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_auto_card_top_up}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min Auto Invoice Top Up, $</h6>
                                        <p>Minimum auto invoice top up</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_auto_invoice_top_up: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_auto_invoice_top_up}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min Auto Credit Card Threshold, $</h6>
                                        <p>Minimum auto credit card threshold</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_auto_card_threshold: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_auto_card_threshold}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Min Auto Invoice Threshold, $</h6>
                                        <p>Minimum auto invoice threshold</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    min_auto_invoice_threshold: e.target.value,
                                                });
                                            }}
                                            value={publisher?.min_auto_invoice_threshold}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Default Theme</h6>
                                        <p>Default theme for the publisher</p>
                                    </Col>
                                    <Col md>
                                        <Form.Select
                                            id="country"
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    default_theme: e.target.value,
                                                });
                                            }}
                                            value={publisher?.default_theme}
                                        >
                                            {
                                                ["light", "dark"].map((theme, index) => (
                                                    <option key={index} value={theme}>
                                                        {theme === "light" ? "Light" : "Dark"}
                                                    </option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Location</h6>
                                        <p>Publisher country</p>
                                    </Col>
                                    <Col md>
                                        <Form.Select
                                            id="country"
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    country_id: Number(e.target.value),
                                                });
                                            }}
                                            value={publisher?.country_id}
                                        >
                                            {countries &&
                                                countries.map((country, index) => (
                                                    <option key={index} value={country.id}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Currency</h6>
                                        <p>Publisher currency</p>
                                    </Col>
                                    <Col md>
                                        <Form.Select
                                            id="currency"
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    default_currency: e.target.value,
                                                });
                                            }}
                                            value={publisher?.default_currency}
                                        >
                                            {
                                                ["USD", "EUR", "GBP", "AUD", "CAD", "JPY", "CNY"].map(
                                                    (currency, index) => (
                                                        <option key={index} value={currency}>
                                                            {currency}
                                                        </option>
                                                    )
                                                )
                                            }
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Approved Billing Types</h6>
                                        <p>Publisher billing types</p>
                                    </Col>
                                    <Col md>
                                        <Select
                                            id="approvedBillingTypes"
                                            onChange={(e) => {
                                                handleBillingTypeChange(e);
                                            }}
                                            options={systemBillingTypes?.map((billingType) => {
                                                return {
                                                    label: billingType.name,
                                                    value: billingType.id,
                                                }
                                            })}
                                            isSearchable={true}
                                            placeholder={""}
                                            value={billingTypes}
                                            isMulti
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Logo Width</h6>
                                        <p>Default Campaign Logo Width</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    logo_width_dimension: e.target.value,
                                                });
                                            }}
                                            value={publisher?.logo_width_dimension}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Logo Height</h6>
                                        <p>Default Campaign Logo Height</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    logo_height_dimension: e.target.value,
                                                });
                                            }}
                                            value={publisher?.logo_height_dimension}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Screenshots Width</h6>
                                        <p>Default Campaign Screenshots Width</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    screenshot_width_dimension: e.target.value,
                                                });
                                            }}
                                            value={publisher?.screenshot_width_dimension}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Screenshots Height</h6>
                                        <p>Default Campaign Screenshots Height</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    screenshot_height_dimension: e.target.value,
                                                });
                                            }}
                                            value={publisher?.screenshot_height_dimension}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md="5">
                                        <h6>Unmonetized Countries</h6>
                                        <p>Countries where the click event will cost $0 for the advertiser</p>
                                    </Col>
                                    <Col md>
                                        <Select
                                            id="unpaidCountries"
                                            onChange={(e) => {
                                                handleCountriesChange(e);
                                            }}
                                            isMulti
                                            options={countries?.map((country) => {
                                                return {
                                                    label: country.name,
                                                    value: country.id,
                                                }
                                            })}
                                            isSearchable={true}
                                            placeholder={""}
                                            value={unpaidCountries}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>

                    {showScrollToTop && (
                        <Button
                            variant="primary"
                            onClick={scrollToTop}
                            className="scroll-to-top-button fs-3"
                        >
                            <i className="ri-arrow-up-line"></i>
                        </Button>
                    )}
                </div>
            </Fragment>
        )
    )
}